// import './VendorOverview.style.scss'

import React, { useEffect, useState } from 'react';
import { capitalize } from '../../../functions';
import Badge from '../../../components/Badge';
import Card from '../../../components/Card/Card';
import Section from '../../../components/Grid/Section';
import DataGrid from '../../../components/DataGrid';
import CellWithAvatar from '../../../components/DataGrid/CellWithAvatar/CellWithAvatar';
import { BadgeProps } from '../../../components/Badge/Badge';
import { useQueryOptions } from '../../../hooks/useQueryOptions';
import { useNavigate, useParams } from 'react-router-dom';
import TableFooter from '../../../components/TableFooter/TableFooter';
import Paginator from '../../../components/Paginator/Paginator';
import TableNumberOfItems from '../../../components/TabelCountItems/TableNumberOfItems';
import { useQuery } from '@apollo/client';
import { GET_VENDOR_USERS_VIEW } from '../../../data/graphql/queries/people';
import { TVendorUsersViewResponse, TVendorUsersViewVariables } from '../../../data/graphql/queries/people/types';
import DeactivatePersonCell from '../../../components/DeactivatePersonCell';
import useCurrentProfile from '../../../hooks/useCurrentProfile';

type TGuestRow = {
  id: number;
  revokeAccess: JSX.Element | null;
  mobilePhone: string;
  name: JSX.Element | string;
  status: JSX.Element | string;
};

const VendorUsersPanel = () => {
  const navigate = useNavigate();
  const currentUser = useCurrentProfile();
  const params = useParams<{ vendorId: string }>();
  const [guestsTableData, setGuestsTableData] = useState<TGuestRow[]>([]);
  const { queryOptions, onChangeNumberOfItems, upsertQueryOptions } = useQueryOptions({
    limit: 10,
    page: 1,
    orderBy: ['UNIT_NUMBER_ASC'],
  });

  const response = useQuery<TVendorUsersViewResponse, TVendorUsersViewVariables>(GET_VENDOR_USERS_VIEW, {
    variables: {
      first: queryOptions.limit,
      offset: (queryOptions.page - 1) * queryOptions.limit,
      filter: {
        personProfileId: {
          isNull: false,
        },
      },
      condition: {
        isDeleted: false,
        vendorId: params.vendorId ? +params.vendorId : -1,
        propertyId: currentUser ? currentUser.propertyId : -1,
      },
    },
  });
  const staff = response?.data?.transactionalDb?.allVendorUserViews?.nodes || [];

  function getStatusBadge(status: string) {
    let theme: BadgeProps['theme'];

    switch (status) {
      case 'active':
        theme = 'info';
        break;
      case 'inactive':
        theme = 'dark';
        break;
      default:
        theme = 'dark';
        break;
    }

    return (
      <Badge theme={theme} size={'sm'}>
        {capitalize(status)}
      </Badge>
    );
  }

  useEffect(() => {
    if (staff) {
      setGuestsTableData(
        staff.map(({ personId, name, mobilePhone, isActive, email, personProfileId }) => {
          return {
            revokeAccess: (
              <DeactivatePersonCell personProfileId={Number(personProfileId)} onRevoke={response.refetch} />
            ),
            mobilePhone: mobilePhone || '—',
            email: email || '—',
            id: +personId,
            name: <CellWithAvatar name={name} />,
            status: getStatusBadge(isActive ? 'active' : 'inactive'),
          };
        }),
      );
    } else {
      setGuestsTableData([]);
    }
  }, [response?.data?.transactionalDb?.allVendorUserViews?.nodes]);

  const itemCount = response.data?.transactionalDb?.allVendorUserViews?.totalCount || 0;

  return (
    <Section>
      <h4 className="black desktop-h4-semibold-22">Staff</h4>
      <Card padding="sm">
        <DataGrid
          loading={response.loading}
          selectableRows
          columns={[
            { key: 'name', name: 'Name' },
            { key: 'status', name: 'Status' },
            { key: 'mobilePhone', name: 'Phone Number' },
            { key: 'email', name: 'Email' },
            { key: 'revokeAccess', name: 'Deactivate person' },
          ]}
          minHeight
          rows={guestsTableData}
          onRowSelect={(index: number) => {
            navigate('user/' + guestsTableData[index].id);
          }}
        />
      </Card>

      <TableFooter itemCount={itemCount}>
        <Paginator
          itemCount={itemCount}
          perPage={queryOptions.limit as number}
          currentPage={queryOptions.page as number}
          onPageChange={(p) =>
            upsertQueryOptions({
              ...queryOptions,
              page: p as number,
            })
          }
        />
        <TableNumberOfItems value={queryOptions.limit} onValueChange={onChangeNumberOfItems} />
      </TableFooter>
    </Section>
  );
};

export default VendorUsersPanel;
